import {
  ActionPayload,
  AoAConfiguration,
  GuestTokenPayload,
  DashboardDataPayload,
  EnableReportingPayload,
  GetDeviceInfosPayload,
  DeviceInfo,
  CreateTagPayload,
  DeleteTagPayload,
  FetchTagsPayload,
  GetScenarioInfosPayload,
  AoAScenarioInfo,
  AssignTagPayload,
  UnassignTagPayload
} from './types';
import {appConfig} from './config';
import {OpenTelemetry} from '@axteams-one/opentelemetry-js-react';
import {getAccessToken} from './helpers/getAccessToken';

const edgelinkFetch = (
  token: string,
  orgArn: string,
  serial: string,
  api: string,
  method: 'GET' | 'POST' = 'GET',
  data?: {apiVersion: string; method: string; params?: object}
): Promise<Response> => {
  const orgId = orgArn.replace('arn:organization:', '');

  return fetch(`${appConfig.edgeLinkURL}/organizations/${orgId}/targets/${serial}/vapix${api}`, {
    method: method,
    headers: {Authorization: `Bearer ${token}`},
    body: data ? JSON.stringify(data) : undefined
  }).catch(err => {
    console.log(err);
    return err;
  });
};

export const soapServices = (token: string, orgArn: string, serial: string, xml: string) => {
  return new Promise((resolve, reject) => {
    try {
      const orgId = orgArn.replace('arn:organization:', '');
      const url = `${appConfig.edgeLinkURL}/organizations/${orgId}/targets/${serial}/vapix/vapix/services`;

      const xhr = new XMLHttpRequest();
      xhr.open('POST', url);
      xhr.setRequestHeader('Content-Type', 'application/xml');
      xhr.setRequestHeader('Authorization', `Bearer ${token}`);

      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve(xhr.responseText);
        } else {
          reject(new Error(`SOAP service request failed with status ${xhr.status}`));
        }
      };

      xhr.onerror = () => {
        reject(new Error('Failed to make SOAP service request'));
      };

      xhr.send(xml);
    } catch (error) {
      reject(error);
    }
  });
};

export const fetchImg = (token: string, orgArn: string, serial: string) =>
  edgelinkFetch(
    token,
    orgArn,
    serial,
    '/axis-cgi/jpg/image.cgi?resolution=320x240&compression=25&camera=1'
  ).then(async res => {
    if (res.ok) {
      const imageBlob = await res.blob();
      return URL.createObjectURL(imageBlob);
    }
  });

export const fetchAoaConfig = (token: string, orgArn: string, serial: string) =>
  edgelinkFetch(token, orgArn, serial, '/local/objectanalytics/control.cgi', 'POST', {
    apiVersion: '1.6',
    method: 'getConfiguration'
  }).then(async res => {
    if (res.ok) {
      return res.json();
    }
  });

export const fetchAoaCapabilitites = (token: string, orgArn: string, serial: string) =>
  edgelinkFetch(token, orgArn, serial, '/local/objectanalytics/control.cgi', 'POST', {
    apiVersion: '1.6',
    method: 'getConfigurationCapabilities'
  }).then(async res => {
    if (res.ok) {
      return res.json();
    }
  });

export const setNewAoaConfig = (
  token: string,
  orgArn: string,
  serial: string,
  config: AoAConfiguration
) =>
  edgelinkFetch(token, orgArn, serial, '/local/objectanalytics/control.cgi', 'POST', {
    apiVersion: '1.6',
    method: 'setConfiguration',
    params: config
  }).then(async res => {
    if (res.ok) {
      return res.json();
    }
  });

export const setAction = async (payload: ActionPayload[]) => {
  try {
    const response = await fetch(`${appConfig.apiURL}/api/create-scenario-actions`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    });

    if (response.ok) {
      return response.json();
    } else {
      throw new Error(`Failed to set action`);
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const fetchActions = async () => {
  try {
    const response = await fetch(`${appConfig.apiURL}/api/aoa-scenario-actions`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (response.ok) {
      return response.json();
    } else {
      throw new Error(`Failed to fetch action`);
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export enum GuestTokenErrors {
  NO_DEVICES = 'NO_DEVICES',
  UNKNOWN = 'UNKNOWN'
}

export const fetchGuestToken = (
  payload: GuestTokenPayload,
  openTelemetry: OpenTelemetry
): Promise<{guestToken: string; error?: GuestTokenErrors}> =>
  dashboardApiFetch('guest_token', openTelemetry, 'POST', payload)
    .then(async rsp => {
      if (rsp.ok) {
        return rsp.json();
      } else if (rsp.status === 400) {
        const errorMsg = await rsp.text();
        if (errorMsg.includes('no devices')) {
          /** @todo, improve error response from BE */
          return {guestToken: '', error: GuestTokenErrors.NO_DEVICES};
        }
      }
      const errorMsg = await rsp.text();
      throw new Error(errorMsg);
    })
    .catch(err => {
      console.error(err);
      return {guestToken: '', error: GuestTokenErrors.UNKNOWN};
    });

export const fetchDashboardData = (
  payload: DashboardDataPayload,
  openTelemetry: OpenTelemetry
): Promise<{uuid: string; name: string; domain: string}[]> =>
  dashboardApiFetch('dashboards', openTelemetry, 'GET', undefined, {
    organizationArn: payload.organizationArn
  })
    .then(rsp => {
      if (rsp.ok) {
        return rsp.json();
      }
      throw new Error(rsp.status.toString());
    })
    .catch(() => []);

export const enableReporting = (payload: EnableReportingPayload, openTelemetry: OpenTelemetry) =>
  dashboardApiFetch('configure_reporting', openTelemetry, 'POST', payload)
    .then(rsp => rsp.ok)
    .catch(err => {
      console.error(err);
      return false;
    });

export const getDeviceInfos = (
  payload: GetDeviceInfosPayload,
  openTelemetry: OpenTelemetry
): Promise<DeviceInfo[] | undefined> =>
  dashboardApiFetch('devices', openTelemetry, 'GET', undefined, {
    organization: payload.organizationArn
  })
    .then(res => {
      if (res.ok) {
        return res.json();
      }
      throw new Error(res.status.toString());
    })
    .catch(err => {
      console.log(err);
      return undefined;
    });

export const getScenarioInfos = (
  payload: GetScenarioInfosPayload,
  openTelemetry: OpenTelemetry
): Promise<AoAScenarioInfo[] | undefined> =>
  dashboardApiFetch('scenarios', openTelemetry, 'GET', undefined, {
    serial: payload.serial,
    organizationId: payload.organizationId
  })
    .then(res => {
      if (res.ok) {
        return res.json();
      }
      throw new Error(res.status.toString());
    })
    .catch(err => {
      console.log(err);
      return undefined;
    });

export const createTag = (
  payload: CreateTagPayload,
  openTelemetry: OpenTelemetry
): Promise<boolean> =>
  dashboardApiFetch('tag', openTelemetry, 'POST', payload)
    .then(rsp => rsp.ok)
    .catch(() => false);

export const deleteTag = (payload: DeleteTagPayload, openTelemetry: OpenTelemetry) =>
  dashboardApiFetch(`tag/${payload.tagName}`, openTelemetry, 'DELETE', undefined, {
    organizationArn: payload.organizationArn
  })
    .then(rsp => rsp.ok)
    .catch(() => false);

export const fetchTags = (
  payload: FetchTagsPayload,
  openTelemetry: OpenTelemetry
): Promise<string[]> =>
  dashboardApiFetch('tag', openTelemetry, 'GET', undefined, {
    organizationArn: payload.organizationArn
  })
    .then(rsp => {
      if (rsp.ok) {
        return rsp.json();
      }
      return [];
    })
    .catch(() => []);

export const assignTag = (
  payload: AssignTagPayload,
  openTelemetry: OpenTelemetry
): Promise<boolean> =>
  dashboardApiFetch('assign_tag', openTelemetry, 'POST', payload)
    .then(rsp => rsp.ok)
    .catch(() => false);

export const unassignTag = (
  payload: UnassignTagPayload,
  openTelemetry: OpenTelemetry
): Promise<boolean> =>
  dashboardApiFetch('unassign_tag', openTelemetry, 'POST', payload)
    .then(rsp => rsp.ok)
    .catch(() => false);

const dashboardApiFetch = (
  apiName: string,
  openTelemetry: OpenTelemetry,
  method: 'GET' | 'POST' | 'DELETE',
  payload?: object,
  searchParam?: Record<string, string>
): Promise<Response> =>
  openTelemetry.runInClientTraceSpan(`${method} ${apiName.split('/')[0]}`, ({traceSpan}) =>
    getAccessToken().then(token =>
      fetch(
        `${appConfig.dashboardApiURL}/api/${apiName}${searchParam ? `?${new URLSearchParams(searchParam)}` : ''}`,
        {
          method: method,
          headers: {
            ...traceSpan.getTraceContextCarrier(),
            ...(method === 'POST' ? {'Content-Type': 'application/json'} : undefined),
            Authorization: 'Bearer ' + token
          },
          body: JSON.stringify(payload)
        }
      )
        .then(rsp => {
          if (rsp.ok) {
            traceSpan.ok();
            return rsp;
          }
          traceSpan.error(rsp.status);
          return rsp;
        })
        .catch(err => {
          traceSpan.error(err);
          console.error(err);
          throw Error(err);
        })
    )
  );

export const deviceReachable = (
  orgArn: string,
  serial: string,
  openTelemetry: OpenTelemetry
): Promise<boolean> =>
  openTelemetry.runInClientTraceSpan('POST unassign_tag', ({traceSpan}) =>
    getAccessToken().then(token =>
      edgelinkFetch(token, orgArn, serial, '/axis-cgi/basicdeviceinfo.cgi', 'POST', {
        apiVersion: '1.0',
        method: 'getAllUnrestrictedProperties'
      })
        .then(rsp => {
          if (rsp.status === 200) {
            traceSpan.ok();
            return true;
          }
          if (rsp.status === 404) {
            traceSpan.ok();
            return false;
          }
          traceSpan.error(rsp.text());
          return false;
        })
        .catch(err => {
          console.error(err);
          traceSpan.error(err);
          return false;
        })
    )
  );
